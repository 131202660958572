import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StampCardListItem } from '../../core/models/stampcards/stampCardListItem';
import { Observable } from 'rxjs';
import { CreateStampCardRequest } from '../../core/models/stampcards/createStampCardRequest';
import { CreateStampCardResponse } from '../../core/models/stampcards/createStampCardResponse';
import { StampCardDetailsDto } from '../../core/models/stampcards/stampCardDetailsDto';
import { UpdateStampCardRequest } from '../../core/models/stampcards/updateStampCardRequest';
import { AssingStampCardToCustomerResponse } from '../../core/models/stampcards/assingStampCardToCustomerResponse';
import { StampStampCardResponse } from '../../core/models/stampcards/stampStampCardResponse';
import { StampCardsCustomersDetailsDto } from '../../core/models/stampcards/stampCardsCustomersDetailsDto';
import { StampCard } from '../../core/models/stampcards/stampCard';

@Injectable({
  providedIn: 'root',
})
export class StampcardsService {
  constructor(private http: HttpClient) {}

  getStampCards(
    isPublished?: boolean,
    name?: string
  ): Observable<StampCardListItem[]> {
    var queryParams = '?';
    if (isPublished!=undefined) {
      queryParams = queryParams + `isPublished=${isPublished}&`;
    }
    if (name) {
      queryParams = queryParams + `name=${name}`;
    }
    return this.http.get<StampCardListItem[]>(`/stampcards${queryParams}`);
  }
  mapStampCardDetailsToPreview(
    dto: StampCardDetailsDto,
    replaceTenantLogoUrl?: string
  ): StampCard {
    var stampCard = {
      id: dto.id,
      name: dto.name,
      title: dto.title,
      stampCount: dto.stampCount,
      termsAndConditions: dto.termsAndConditions,
      cardBackgroundColorHex: dto.cardBackgroundColorHex || '', // Map nulls to empty strings if needed
      emptyStampBackgroundColorHex: dto.emptyStampBackgroundColorHex || '',
      usedStampBackgroundColorHex: dto.usedStampBackgroundColorHex || '',
      cardBackgroundImageUrl: dto.cardBackgroundImageUrl || '',
      stampIconUrl: dto.stampIconUploadedUrl || dto.stampIconLibraryUrl || '', // Prioritize uploaded or library icon
      couponId: dto.coupon?.id || '', // Assuming CouponId is mapped from the associated coupon
      expireCardAfterRecivedInDays: dto.expireCardAfterRecivedInDays,
      expireCouponAfterRecivedInDays: dto.expireCouponAfterRecivedInDays,
      showCompanyLogo: dto.showCompanyLogo,
      uploadedCompanyLogoUrl:
        dto.uploadedCompanyLogoUrl ?? replaceTenantLogoUrl,
      showCompanyName: dto.showCompanyName,
      companyName: dto.companyName,
      companyNameColorHex: dto.companyNameColorHex,
      cardTitleColorHex: dto.cardTitleColorHex,
      stampBorderColorHex: dto.stampBorderColorHex,
      expirationDateColorHex: dto.expirationDateColorHex,
    };
    if (!dto.cardBackgroundImageUrl && !dto.cardBackgroundColorHex) {
      stampCard.cardBackgroundImageUrl = 'assets/images/stampcard-empty.png';
    }
    if (dto.stampIconUploadedUrl) {
      stampCard.stampIconUrl = dto.stampIconUploadedUrl;
    }

    return stampCard;
  }

  getStampCardDetails(id: string): Observable<StampCardDetailsDto> {
    return this.http.get<StampCardDetailsDto>(`/stampcards/${id}`);
  }
  updateStampCard(
    id: string,
    request: UpdateStampCardRequest
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('name', request.name);
    formData.append('title', request.title);
    formData.append('stampCount', request.stampCount.toString());
    formData.append('termsAndConditions', request.termsAndConditions);
    if (request.cardBackgroundColorHex != null) {
      formData.append('cardBackgroundColorHex', request.cardBackgroundColorHex);
    }
    if (request.emptyStampBackgroundColorHex != null) {
      formData.append(
        'emptyStampBackgroundColorHex',
        request.emptyStampBackgroundColorHex
      );
    }
    if (request.usedStampBackgroundColorHex != null) {
      formData.append(
        'usedStampBackgroundColorHex',
        request.usedStampBackgroundColorHex
      );
    }
    formData.append(
      'isCardBackgroundImageRemoved',
      request.isCardBackgroundImageRemoved.toString()
    );
    if (request.cardBackgroundImage != null) {
      formData.append('cardBackgroundImage', request.cardBackgroundImage);
    }
    formData.append(
      'isStampIconUploadRemoved',
      request.isStampIconUploadRemoved.toString()
    );
    if (request.stampIconUpload != null) {
      formData.append('stampIconUpload', request.stampIconUpload);
    }
    if (request.stampIconId != null) {
      formData.append('stampIconId', request.stampIconId);
    }

    if (request.expireCardAfterRecivedInDays != null) {
      formData.append(
        'expireCardAfterRecivedInDays',
        request.expireCardAfterRecivedInDays.toString()
      );
    }
    if (request.expireCouponAfterRecivedInDays != null) {
      formData.append(
        'expireCouponAfterRecivedInDays',
        request.expireCouponAfterRecivedInDays.toString()
      );
    }
    formData.append('couponId', request.couponId);
    formData.append('showCompanyLogo', request.showCompanyLogo.toString());
    formData.append(
      'currentUploadedCompanyLogoDeleted',
      request.currentUploadedCompanyLogoDeleted.toString()
    );
    if (request.companyLogoUploaded) {
      formData.append('companyLogoUploaded', request.companyLogoUploaded);
    }
    formData.append('showCompanyName', request.showCompanyName.toString());
    if (request.companyName) {
      formData.append('companyName', request.companyName);
    }
    formData.append('companyNameColorHex', request.companyNameColorHex);
    formData.append('cardTitleColorHex', request.cardTitleColorHex);
    formData.append('stampBorderColorHex', request.stampBorderColorHex);
    formData.append('expirationDateColorHex', request.expirationDateColorHex);

    return this.http.put<any>(`/stampcards/${id}`, formData);
  }
  deleteStampCard(id: string): Observable<any> {
    return this.http.delete<any>(`/stampcards/${id}`);
  }
  publishStampCard(id: string): Observable<any> {
    return this.http.put<any>(`/stampcards/${id}/publish`, null);
  }
  assignStampCard(
    id: string,
    customerUserName: string
  ): Observable<AssingStampCardToCustomerResponse> {
    return this.http.post<AssingStampCardToCustomerResponse>(
      `/stampcards/${id}/assign?customerUserName=${customerUserName}`,
      null
    );
  }
  unAssignStampCard(id: string, customerUserName: string): Observable<any> {
    return this.http.delete<any>(
      `/stampcards/${id}/unassign?customerUserName=${customerUserName}`
    );
  }
  getCustomerStampCard(
    id: string,
    customerUserName: string
  ): Observable<StampCardsCustomersDetailsDto> {
    return this.http.get<StampCardsCustomersDetailsDto>(
      `/stampcards/${id}/stamp?customerUserName=${customerUserName}`
    );
  }
  stampStampCard(
    id: string,
    customerUserName: string
  ): Observable<StampStampCardResponse> {
    return this.http.post<StampStampCardResponse>(
      `/stampcards/${id}/stamp?customerUserName=${customerUserName}`,
      null
    );
  }
  createStampCard(
    request: CreateStampCardRequest
  ): Observable<CreateStampCardResponse> {
    const formData: FormData = new FormData();
    formData.append('name', request.name);
    formData.append('title', request.title);
    formData.append('stampCount', request.stampCount.toString());
    formData.append('termsAndConditions', request.termsAndConditions);
    if (request.cardBackgroundColorHex != null) {
      formData.append('cardBackgroundColorHex', request.cardBackgroundColorHex);
    }
    if (request.emptyStampBackgroundColorHex != null) {
      formData.append(
        'emptyStampBackgroundColorHex',
        request.emptyStampBackgroundColorHex
      );
    }
    if (request.usedStampBackgroundColorHex != null) {
      formData.append(
        'usedStampBackgroundColorHex',
        request.usedStampBackgroundColorHex
      );
    }
    if (request.cardBackgroundImage != null) {
      formData.append('cardBackgroundImage', request.cardBackgroundImage);
    }
    if (request.stampIconUpload != null) {
      formData.append('stampIconUpload', request.stampIconUpload);
    }
    if (request.stampIconId != null) {
      formData.append('stampIconId', request.stampIconId);
    }

    if (request.expireCardAfterRecivedInDays != null) {
      formData.append(
        'expireCardAfterRecivedInDays',
        request.expireCardAfterRecivedInDays.toString()
      );
    }
    if (request.expireCouponAfterRecivedInDays != null) {
      formData.append(
        'expireCouponAfterRecivedInDays',
        request.expireCouponAfterRecivedInDays.toString()
      );
    }
    formData.append('couponId', request.couponId);
    formData.append('showCompanyLogo', request.showCompanyLogo.toString());
    if (request.companyLogoUploaded) {
      formData.append('companyLogoUploaded', request.companyLogoUploaded);
    }
    formData.append('showCompanyName', request.showCompanyName.toString());
    if (request.companyName) {
      formData.append('companyName', request.companyName);
    }
    formData.append('companyNameColorHex', request.companyNameColorHex);
    formData.append('cardTitleColorHex', request.cardTitleColorHex);
    formData.append('stampBorderColorHex', request.stampBorderColorHex);
    formData.append('expirationDateColorHex', request.expirationDateColorHex);

    return this.http.post<CreateStampCardResponse>(`/stampcards`, formData);
  }
}
