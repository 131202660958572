<div class="w-full flex flex-column">
  <div class="w-full flex flex-column mt-2 px-4">
    <div
      class="w-full flex justify-content-between align-items-center gap-2 mb-2"
    >
      <input
        id="userName"
        type="text"
        pInputText
        [(ngModel)]="customerUserName"
        placeholder="Search Customer ID"
        class="block w-full"
      />
      <p-button
        size="small"
        label="Find"
        icon="pi pi-search"
        severity="success"
        [disabled]="isLoading"
        [loading]="isLoading"
        (click)="getCustomerDetails()"
      ></p-button>
    </div>
    <div class="w-full flex flex-column gap-2" *ngIf="customersDetails">
      <div class="card flex flex-column p-2 mb-2">
        <h1 class="m-0 text-xl font-bold">
          {{ customersDetails.firstName }} {{ customersDetails.lastName }}
        </h1>
        <p>ID : {{ customersDetails.userName }}</p>
      </div>
      <div class="w-full flex justify-content-end align-items-center gap-2">
        <p-button
          size="small"
          label="Cancel"
          [outlined]="true"
          (click)="clearSearchedCustomer()"
        ></p-button>
        <p-button
          size="small"
          label="View Profile"
          (click)="viewCustomerProfile()"
        ></p-button>
      </div>
    </div>
  </div>
  <div class="centered-heading my-2 px-4">
    <span class="line"></span>
    <span class="text">Or</span>
    <span class="line"></span>
  </div>

  <zxing-scanner
    *ngIf="!isBusy"
    (scanSuccess)="handleQrCodeResult($event)"
    [device]="currentDevice"
    [formats]="allowedFormats"
  >
  </zxing-scanner>
  <div class="w-full flex justify-content-between align-items-center p-4">
    <div></div>
    <div class="flex align-items-center">
      <p-progressSpinner
        styleClass="w-4rem h-4rem"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      />
      <p class="ml-2 m-0">{{ status }}</p>
    </div>
    <p-button size="small" label="Close" (click)="closeDialog()"></p-button>
  </div>
</div>
