import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ITokens } from '../constants/ITokens';
import { BroadcasterService } from './broadcaster.service';
import { BroadcastKeys } from '../constants/Broadcast';
import { Router } from '@angular/router';
import { ToastService } from './toast.service';
import { AuthenticationService } from '../../authentication/services/authentication.service';


@Injectable()
export class HTTPReqResInterceptor implements HttpInterceptor {
  constructor(
    @Inject(ITokens.API_URL) private _baseUrl: string,
    private broadcastService: BroadcasterService,
    private router: Router,
    private toster: ToastService,
    private authService:AuthenticationService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.has('X-Skip-Interceptor')) {
      const headers = req.headers.delete('X-Skip-Interceptor');
      const clonedRequest = req.clone({ headers });
      return next.handle(clonedRequest);
    }
    this.broadcastService.broadcast(BroadcastKeys.HTTP_LOADING, true);
    const newReq = req.clone({
      url: this._baseUrl + req.url,
      headers: req.headers.set(
        'Authorization',
        `Bearer ${this.authService.authUser?.token}`
      ),
    });
 
    return next.handle(newReq).pipe(
      catchError((err) => this.handleError(newReq, next, err)),
      finalize(() => {
        this.broadcastService.broadcast(BroadcastKeys.HTTP_LOADING, false);
      })
    );
  }

  handleError(newRequest: HttpRequest<any>, next: HttpHandler, err: any) {
    if (err instanceof HttpErrorResponse && err.status === 401) {
      this.toster.errorToast("Error",err.error.Errors.Error)
      this.authService.setAuthUser(null);
      this.router.navigate(['/auth/login']);
    } 
    else if (err instanceof HttpErrorResponse && err.status === 410) {
      this.authService.setAuthUser(null);
      this.toster.errorToast("Error","Company is disabled.")
      this.router.navigate(['/auth/error']);
    } 
    else if (err instanceof HttpErrorResponse && err.status === 403) {
      this.toster.errorToast("Error",err.error.Errors.Error)
      this.router.navigate(['/tenant']);
    } 
    else {
      if (err.error.errors) {
        for (var key in err.error.errors) {
          if (err.error.errors.hasOwnProperty(key)) {
            this.toster.errorToast(key, err.error.errors[key]);
          }
        }
      } 
      else if (err.error.Errors) {
        for (var key in err.error.Errors) {
          if (err.error.Errors.hasOwnProperty(key)) {
            this.toster.errorToast(key, err.error.Errors[key]);
          }
        }
      } 
      else {
        this.toster.errorToast('Error', 'Hmm.. Something went wrong!');
      }
    }
    return throwError(err);
  }
}
