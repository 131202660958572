<mat-toolbar class="bottm-nav p-1">
  <p-button
    *ngFor="let feature of flList"
    [icon]="feature.icon"
    [routerLink]="feature.routerLink"
    [rounded]="true"
    [text]="true"
    [styleClass]="route.activeUrlSegment != getRouteSegment(feature)?'text-gray-500':'text-primary'"
    size="large"
  ></p-button>
  <div class="qr-button-container">
    <p-button
      (click)="qrScanner()"
      icon="pi pi-qrcode"
      [rounded]="true"
      size="large"
    ></p-button>
  </div>
  <div></div>
  <p-button
    *ngFor="let feature of frList"
    [icon]="feature.icon"
    [routerLink]="feature.routerLink"
    [rounded]="true"
    [text]="true"
    [styleClass]="route.activeUrlSegment != getRouteSegment(feature)?'text-gray-500':'text-primary'"
    size="large"
  ></p-button>
  <p-button
    (click)="onSettingsClick()"
    icon="pi pi-bars"
    [rounded]="true"
    [text]="true"
    [styleClass]="!isSettings(route.activeUrlSegment)?'text-gray-500':'text-primary'"
    size="large"
  ></p-button>
</mat-toolbar>
