import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouteResolverService } from '../../service/route-resolver.service';
import { LayoutService } from '../../service/app.layout.service';
import {
  RouteDescription,
  featurePriorities,
} from 'src/app/modules/core/constants/routeDescriptions';

@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss'],
})
export class BottomNavComponent implements OnInit {
  @Input() rewardoFeatures!: RouteDescription[];
  fList: RouteDescription[] = [];
  flList: RouteDescription[] = [];
  frList: RouteDescription[] = [];
  @Output() openQRScanDialog: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(
    public route: RouteResolverService,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    for (let index = 0; index < featurePriorities.length; index++) {
      const element = featurePriorities[index];
      if (this.fList.length == 4) {
        break;
      }
      if (this.rewardoFeatures.find((tf) => tf.label == element) != null) {
        this.fList = [
          ...this.fList,
          this.rewardoFeatures.find((tf) => tf.label == element)!,
        ];
      }
      if (this.fList.length > 0) {
        // Assign the first two elements to flList if they exist
        this.flList = this.fList.slice(0, 2);
      
        // Assign the next two elements to frList if they exist
        this.frList = this.fList.slice(2, 3);
      }

    }
  }

  isSettings(url: string): boolean {
    var urlSegments = this.fList.map((u) => this.getRouteSegment(u));
    if (urlSegments.find((s) => s == url) == null) {
      return true;
    }
    return false;
  }
  onSettingsClick() {
    this.layoutService.showProfileSidebar();
  }
  qrScanner(){
    this.openQRScanDialog.emit(true)
  }
  getRouteSegment(route: RouteDescription) {
    return route.routerLink[0].split('/')[1];
  }
}
