import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerDetailsResponse } from '../../core/customers/customerDetailsResponse';
import { Observable } from 'rxjs';
import { CustomerBasicDetailsResponse } from '../../core/customers/customerBasicDetailsResponse';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  constructor(private http: HttpClient) {}

  getCustomerDetails(userName: string): Observable<CustomerDetailsResponse> {
    return this.http.get<CustomerDetailsResponse>(`/customers/${userName}`);
  }
  getCustomerBasicDetails(
    userName: string
  ): Observable<CustomerBasicDetailsResponse> {
    return this.http.get<CustomerBasicDetailsResponse>(
      `/customers/${userName}/basics`
    );
  }
}
