import { EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Component } from '@angular/core';
import { BaseComponent } from '../modules/core/components/base/base.component';
import { BroadcasterService } from '../modules/core/services/broadcaster.service';
import { AuthenticationService } from '../modules/authentication/services/authentication.service';
import { RouteDescription } from '../modules/core/constants/routeDescriptions';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  cameraDialog: any = {
    label: 'QR',
    icon: 'pi pi-qrcode',
    command: () => {
      this.openQRScanDialog.emit(true);
    },
  };

  @Input() rewardoFeatures!: RouteDescription[];
  @Output() openQRScanDialog: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(
    authService: AuthenticationService,
    broadcastService: BroadcasterService
  ) {
    super(broadcastService, authService);
  }
  override ngOnInit() {
    super.ngOnInit();
  }
  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
