<div class="w-full relative flex flex-column carousel-container">
  <div *ngIf="items.length>1" class="w-full flex justify-content-end align-items-center gap-2 mb-4">
    <p-button
      icon="pi pi-angle-left"
      [rounded]="true"
      (click)="prev()"
      [raised]="true"
    />
    <p-button
      icon="pi pi-angle-right"
      [rounded]="true"
      (click)="next()"
      [raised]="true"
    />
  </div>
  <div
    class="w-full carousel-item fadein"
    *ngFor="let item of items; let i = index"
    [ngClass]=" (i === currentIndex ? ' active '+ activeItemStyle : '')"
  >
    <!-- Dynamically render the template for each item -->
    <ng-container
      *ngTemplateOutlet="template; context: { $implicit: item }"
    ></ng-container>
  </div>
</div>
