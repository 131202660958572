import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { AvatarModule } from 'primeng/avatar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { BadgeModule } from 'primeng/badge';
import { EmptyValuePipe } from './pipes/empty-value.pipe';
import { ShowForUserDirective } from './directives/show-for-user.directive';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { PasswordModule } from 'primeng/password';
import { FileUploadModule } from 'primeng/fileupload';
import { MenuModule } from 'primeng/menu';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { CalendarModule } from 'primeng/calendar';
import { AccordionModule } from 'primeng/accordion';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DialogModule } from 'primeng/dialog';
import { LimitStringLengthPipe } from './pipes/limit-string-length.pipe';
import { SkeletonModule } from 'primeng/skeleton';
import { TagModule } from 'primeng/tag';
import { InputNumberModule } from 'primeng/inputnumber';
import { NameInitialsPipe } from './pipes/name-initials.pipe';
import { ShowForOwnerDirective } from './directives/show-for-owner.directive';
import { RemoveAMPMPipe } from './pipes/remove-ampm.pipe';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { PaginatorModule } from 'primeng/paginator';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToggleFeatureDirective } from './directives/toggle-feature.directive';
import { TimeampmFromDatePipe } from './pipes/timeampm-from-date.pipe';
import { HideForUserDirective } from './directives/hide-for-user.directive';
import { LimitStringLengthNoCharPipe } from './pipes/limit-string-length-no-char.pipe';
import { Timein24FromDatePipe } from './pipes/timein24-from-date.pipe';
import { Timein24FromTimePipe } from './pipes/timein24-from-time.pipe';
import { RoundNumberPipe } from './pipes/round-number.pipe';
import { TimeDatePipe } from './pipes/time-date.pipe';
import { HideForAuthUserDirective } from './directives/hide-for-auth-user.directive';
import { ShowForUserAndOwnerDirective } from './directives/show-for-user-and-owner.directive';
import { HideForOwnerDirective } from './directives/hide-for-owner.directive';
import { TimeDifferencePipe } from './pipes/time-difference.pipe';
import { TableEmptyMessageComponent } from './components/table-empty-message/table-empty-message.component';
import { GalleriaModule } from 'primeng/galleria';
import { CouponExpireOrNeverPipe } from './pipes/coupon-expire-or-never.pipe';
import { CouponTypeToStringPipe } from './pipes/coupon-type-to-string.pipe';
import { StampCardComponent } from './components/stamp-card/stamp-card.component';
import { CouponComponent } from './components/coupon/coupon.component';
import { StampStampcardDialogComponent } from './components/stamp-stampcard-dialog/stamp-stampcard-dialog.component';
import { UseCouponDialogComponent } from './components/use-coupon-dialog/use-coupon-dialog.component';
import { ScanQrDialogComponent } from './components/scan-qr-dialog/scan-qr-dialog.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CustomCarousalComponent } from './components/custom-carousal/custom-carousal.component';

@NgModule({
  declarations: [
    EmptyValuePipe,
    ShowForUserDirective,
    LimitStringLengthPipe,
    NameInitialsPipe,
    ShowForOwnerDirective,
    RemoveAMPMPipe,
    ToggleFeatureDirective,
    TimeampmFromDatePipe,
    HideForUserDirective,
    LimitStringLengthNoCharPipe,
    Timein24FromDatePipe,
    Timein24FromTimePipe,
    RoundNumberPipe,
    TimeDatePipe,
    HideForAuthUserDirective,
    ShowForUserAndOwnerDirective,
    HideForOwnerDirective,
    TimeDifferencePipe,
    TableEmptyMessageComponent,
    CouponExpireOrNeverPipe,
    CouponTypeToStringPipe,
    StampCardComponent,
    CouponComponent,
    StampStampcardDialogComponent,
    UseCouponDialogComponent,
    ScanQrDialogComponent,
    CustomCarousalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    ButtonModule,
    InputTextModule,
    TableModule,
    AvatarModule,
    ConfirmDialogModule,
    BadgeModule,
    DropdownModule,
    MultiSelectModule,
    PasswordModule,
    FileUploadModule,
    MenuModule,
    AvatarGroupModule,
    CheckboxModule,
    TabViewModule,
    DynamicDialogModule,
    CalendarModule,
    AccordionModule,
    InputTextareaModule,
    RadioButtonModule,
    DialogModule,
    SkeletonModule,
    TagModule,
    InputNumberModule,
    ConfirmPopupModule,
    PaginatorModule,
    InputSwitchModule,
    GalleriaModule,
    ZXingScannerModule,
    ProgressSpinnerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    ButtonModule,
    InputTextModule,
    TableModule,
    AvatarModule,
    ConfirmDialogModule,
    BadgeModule,
    EmptyValuePipe,
    ShowForUserDirective,
    DropdownModule,
    MultiSelectModule,
    PasswordModule,
    FileUploadModule,
    MenuModule,
    AvatarGroupModule,
    CheckboxModule,
    TabViewModule,
    DynamicDialogModule,
    CalendarModule,
    AccordionModule,
    InputTextareaModule,
    RadioButtonModule,
    DialogModule,
    LimitStringLengthPipe,
    SkeletonModule,
    TagModule,
    InputNumberModule,
    NameInitialsPipe,
    ShowForOwnerDirective,
    RemoveAMPMPipe,
    ConfirmPopupModule,
    PaginatorModule,
    ToggleFeatureDirective,
    TimeampmFromDatePipe,
    HideForUserDirective,
    LimitStringLengthNoCharPipe,
    Timein24FromDatePipe,
    Timein24FromTimePipe,
    RoundNumberPipe,
    TimeDatePipe,
    HideForAuthUserDirective,
    InputSwitchModule ,
    ShowForUserAndOwnerDirective,
    HideForOwnerDirective,
    TimeDifferencePipe,
    TableEmptyMessageComponent,
    GalleriaModule,
    CouponExpireOrNeverPipe,
    CouponTypeToStringPipe,
    StampCardComponent,
    CouponComponent,
    StampStampcardDialogComponent,
    UseCouponDialogComponent,
    ScanQrDialogComponent,
    CustomCarousalComponent
  ],
})
export class SharedModule {}
