import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BarcodeFormat } from '@zxing/library';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { BaseComponent } from 'src/app/modules/core/components/base/base.component';
import { CustomerBasicDetailsResponse } from 'src/app/modules/core/customers/customerBasicDetailsResponse';
import { BroadcasterService } from 'src/app/modules/core/services/broadcaster.service';
import { CouponsService } from 'src/app/modules/coupons/services/coupons.service';
import { CustomersService } from 'src/app/modules/customers/services/customers.service';
import { StampcardsService } from 'src/app/modules/stamp-cards/services/stampcards.service';

type Profile = {
  customerUserName: string;
};

type StampCard = {
  stampCardId: string;
  customerUserName: string;
};

type Coupon = {
  couponCustomersId: string;
  customerUserName: string;
};
type ParsedData = Profile | StampCard | Coupon;

@Component({
  selector: 'app-scan-qr-dialog',
  templateUrl: './scan-qr-dialog.component.html',
  styleUrls: ['./scan-qr-dialog.component.scss'],
})
export class ScanQrDialogComponent extends BaseComponent implements OnInit {
  allowedFormats = [BarcodeFormat.QR_CODE];
  currentDevice!: MediaDeviceInfo;
  status: string = 'Scanning...';
  isBusy: boolean = false;
  customerUserName: string = '';
  customersDetails: CustomerBasicDetailsResponse | undefined;
  constructor(
    broadcastService: BroadcasterService,
    authService: AuthenticationService,
    public router: Router,
    public ref: DynamicDialogRef,
    public stampcardsService: StampcardsService,
    public couponsService: CouponsService,
    public customersService: CustomersService
  ) {
    super(broadcastService, authService);
  }
  handleQrCodeResult(result: string): void {
    //profile - customerUserName=''
    //qr - stampCardId=''&customerUserName=''
    //coupon - couponCustomersId=''&customerUserName=''
    const data = this.parseQueryString(result);

    if (this.isProfile(data)) {
      this.isBusy = true;
      this.status = 'Found customer. Redirecting';
      this.router.navigate(['/customers', data.customerUserName]);
      this.ref.close();
    } else if (this.isQR(data)) {
      this.isBusy = true;
      this.stampcardsService
        .getCustomerStampCard(
          (data as StampCard).stampCardId,
          (data as StampCard).customerUserName
        )
        .subscribe(
          (data) => {
            this.ref.close({
              stampCard: data,
            });
          },
          () => {
            this.status = 'Scanning...';
            this.isBusy = false;
          }
        );
    } else if (this.isCoupon(data)) {
      this.isBusy = true;
      this.couponsService
        .getCustomerCoupon(
          (data as Coupon).couponCustomersId,
          (data as Coupon).customerUserName
        )
        .subscribe(
          (data) => {
            this.ref.close({
              coupon: data,
            });
          },
          () => {
            this.status = 'Scanning...';
            this.isBusy = false;
          }
        );
    }
  }
  isProfile(data: ParsedData): data is Profile {
    return (
      (data as Profile).customerUserName !== undefined &&
      !(data as StampCard).stampCardId &&
      !(data as Coupon).couponCustomersId
    );
  }

  // Type guard to check if ParsedData is QR
  isQR(data: ParsedData): data is StampCard {
    return (data as StampCard).stampCardId !== undefined;
  }

  // Type guard to check if ParsedData is Coupon
  isCoupon(data: ParsedData): data is Coupon {
    return (data as Coupon).couponCustomersId !== undefined;
  }
  parseQueryString = (queryString: string): ParsedData => {
    const params = queryString.split('&');

    const result: any = {};

    params.forEach((param) => {
      const [key, value] = param.split('=');
      result[key] = value || '';
    });

    if ('couponCustomersId' in result && 'customerUserName' in result) {
      return {
        couponCustomersId: result.couponCustomersId,
        customerUserName: result.customerUserName,
      } as Coupon;
    } else if ('stampCardId' in result && 'customerUserName' in result) {
      return {
        stampCardId: result.stampCardId,
        customerUserName: result.customerUserName,
      } as StampCard;
    } else if ('customerUserName' in result) {
      return {
        customerUserName: result.customerUserName,
      } as Profile;
    }

    return { customerUserName: '' } as Profile;
  };
  getCustomerDetails() {
    this.customersService
      .getCustomerBasicDetails(this.customerUserName)
      .subscribe((data) => {
        this.customersDetails = data;
      });
  }
  clearSearchedCustomer() {
    this.customerUserName = '';
    this.customersDetails = undefined;
  }
  viewCustomerProfile() {
    this.router.navigate(['/customers', this.customersDetails!.userName]);
    this.ref.close();
  }
  closeDialog() {
    this.ref.close();
  }
}
